import React from 'react'
import Intro from './components/intro/Intro'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import './App.css';
import ScrollTopArrow from './components/ScrollTopArrow';
import Navbar from './components/navbar/Navbar'
import Portfolio from './components/portfolio/Portfolio'


const App = () => {


  return (
    <div className="App">
      <div className="gradient__bg">
      <Navbar/>
      <Intro/>
      </div>
      <About/>
      <Portfolio/>
      <div className="gradient__bg2"><Contact/> </div>
      
      <ScrollTopArrow/>
    </div>

  )
}

export default App