import React, { useState} from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import {Link} from "react-scroll"
import './navbar.css'
import logo from '../../img/logo.png'

const Navbar = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if(window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <div className={navbar ? 'header active' : 'header'}>
            <nav className='navbar'>
                <li className='li-logo'>
                <Link to='i' spy={true} smooth={true} offset={0} duration={500} onClick={closeMenu}><img src={logo} alt='logo' className="logo" /></Link>
                </li>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <Link to='i' spy={true} smooth={true} offset={0} duration={500} onClick={closeMenu}>Domov</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='a' spy={true} smooth={true} offset={-30} duration={500} onClick={closeMenu}>O mne</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='portfolio' spy={true} smooth={true} offset={-90} duration={500} onClick={closeMenu}>Produkty</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='c' spy={true} smooth={true} offset={-40} duration={500} onClick={closeMenu}>Kontakt</Link>
                        
                    </li>
                </ul>
       
            </nav>
           
        </div>
    )
}

export default Navbar