import React from 'react'
import "./portfolio.css"
import Card from "../Card.jsx"
import Image1 from '../../img/Capture6.PNG'
import Image2 from '../../img/Capture.PNG'
import Image3 from '../../img/Capture2.PNG'
import Image4 from '../../img/Capture3.PNG'
import Image5 from '../../img/Capture4.PNG'
import Image6 from '../../img/Capture5.PNG'

const data = [
    {   
        img: Image1,
        heading: "Web stránky",
        para: "Online prezentácia pre firmy, podnikateľov, ale aj fyz. osoby.",
        show: "Tvorím web stránky na mieru, ktoré posunú váš business na ďalší level. Prehľadné a pútavé. Aby sa zákazníkom páčili a radi spravili objednávku. Súčasťou webu je samozrejme kvalitné SEO. Vyladím váš web, aby si rozumel s ľuďmi aj s robotmi. Pripravím SEO audit. Zameriam sa, aby sme web optimalizovali zvnútra aj zvonka.",
        URL: "https://demo.wpzoom.com/inspiro-lite/",
    },
    {
        img: Image2,
        heading: "E-shopy",
        para: "Internetový obchod alebo e-shop, komplexné funkcionality na mieru.",
        show: "Vaša nová webová stránka bude stroj na lákanie návštevníkov, ich premenu na vašich zákazníkov a zvyšovanie vášho obratu. Vašu web stránku urobím prehľadnú, vzhľadovo príjemnú pre užívateľa, jej ovládanie bude čo najjednoduchšie. Pre jednoduchšie e-shopy s menším množstvom produktov tvorím na wordpress platforme zvanej WooCommerce. V prípade potreby, rád doprogramujem potrebné funkcionality. Samozrejmou súčasťou E-shopu je skladový manažment, admin panel na správu produktov, platobné brány a taktiež možnosť voľby dopravcov Zásielkovňa, Slovenská pošta, atd.",
        URL:"https://demo.athemes.com/themes/?theme=Botiga",
    },
    {
        img: Image3,
        heading: "Mobilné aplikácie",
        para: "Vytvorené pre operačné systémy iOS a Android. Plne responzívne.",
        show: "Na rozdiel od hybridných aplikácií založených na HTML5, majú natívne mobilné aplikácie viacero výhod. Medzi výhody natívnych aplikácií patrí to, že dokážu plne využívať podporu zariadenia, t.j. plný prístup ku GPS lokácii, kamere na zariadení, mikrofónu, kontaktom atď. Vďaka týmto vlastnostiam je možné takúto aplikáciu použiť na akýkoľvek účel. Ďalšou výhodou je, že natívne aplikácie pracujú na väčšine zariadeniach rýchlejšie a stabilnejšie v porovnaní s hybridnými aplikáciami. Hybridné aplikácie ponúkajú oproti klasickým natívnym aplikáciam niekoľko výhod. Medzi hlavné patrí, že v zariadení sa správajú rovnako ako natívne. Ďalšou výhodou je, že sú stavané na kódoch, ktoré sa používajú pri webových riešeniach. To znamená, že pri prechode výrobcu operačného systému na vyššiu verziu mnohokrát nie je potrebné ich prerobenie na novú verziu operačného systému. Ďalšou nespornou výhodou je, že narozdiel od natívneho vývoja, stačí vytvoriť iba jednu aplikáciu a aplikácia sa nemusí vyvíjať pre viac zariadení samostatne, čo šetrí peniaze aj čas pri vývoji. Takúto aplikáciu využijete, ak potrebujete vyvinúť aplikáciu s menším počtom funkcionalít a nemáte príliš vysoké nároky na rozloženie prvkov.",
        URL: "http://preview.themeforest.net/item/upmobile-html-mobile-template/full_screen_preview/19311698?_ga=2.256616076.648932754.1669071255-240558774.1624742460",
    },
    {
        img: Image4,
        heading: "Objednávkový a rezervačný systém",
        para: "Ideálne napr. pre lekárov, trénerov, hotely, reštaurácie.",
        show: "Všetky objednávky, alebo rezervácie sú na jednom mieste k dispozícii 24 7. O všetkých objednávkach máte dokonalý prehľad a jednoduchšie si naplánujete svoje kapacity. Súčasťou riešenia sú aj pripomenutia pomocou SMS alebo emailov.",
        URL: "https://www.bookinglive.com/",
    },
    {
        img: Image5,
        heading: "Podnikové riešenia",
        para: "Zefektívnite vašu prácu, výkon vašich zamestnancov, vzťahy so zákaníkmi.",
        show: "Firemný systém by mal tvoriť súčasť každej strednej a väčšej spoločnosti. Vďaka nemu dokážete výrazne zjednodušiť a zefektívniť fungovanie vašej firmy a tým ušetriť množstvo finančných prostriedkov vynaložených na mzdy a prípadné chyby. Na základe vašich požiadaviek dokážem vypracovať takmer akýkoľvek firemný systém. Základom úspešného vytvorenia systému je pochopenie vašich potrieb a následné spracovanie projektovej dokumentácie. Projektová dokumentácia slúži na popísanie celého projektu a navrhnutie jednotlivých jeho častí, ktoré sú následne popísané z funkčného hľadiska. Po vypracovaní dokumentácie a jej následnom schválení upresním časový a cenový odhad na vývoj a pustím sa do programovacích prác.",
        URL: "",
    },
    {
        img: Image6,
        heading: "Doplnkové služby",
        para: "3D modeling, grafika, animácie, logá a firemná identita.",
        show: "",
        URL: "https://www.figma.com/files/recent?fuid=1161642710877764063",
    }
]



const Portfolio = () => {

    return(
        <>       
        <section className="portfolio"> 
        <div className="pl-texts">
        <h1 className="pl-title">Portfólio produktov</h1>
        <p className="pl-desc">
          Potrebujete E-shop, Web prezentáciu Vášho businessu, osoby alebo eventu? A čo takto lákavý blog, ktorý zvýrazní pútavý obsah? Vyberám z dizajnových návrhov možných riešení. 
        </p>
        </div>
            <div className="container portfolio__container">
            {data.map((item, index) => {
                return <Card key={index} item={item}/>;
            })}
        </div>
        </section>
        </>
    )
   
}


export default Portfolio