import React from 'react'
import "./intro.css"
import Me from "../../img/me.png" 

const Intro = () => {
  return (
    <div className="i">
        <div className="i-left">
            <div className="i-left-wrapper">
                
                <h1 className="i-name">Využite moderné web technológie na rast svojho businessu </h1>
                <h2 className="i-intro"> web & mobile development</h2>
                <div className="i-title">
                    <div className="i-title-wrapper">
                        <div className="i-title-item">UI/UX Web design</div>
                        <div className="i-title-item">Webové stránky</div>
                        <div className="i-title-item">E-shopy</div>
                        <div className="i-title-item">Mobilné aplikácie</div>
                        <div className="i-title-item">Servis a poradenstvo</div>
                    </div>
                </div>
                <p className="i-desc">
                    Dizajnujem a tvorím digitálne riešenia pre zákazníkov akéhokoľvek zamerania a veľkosti. Špecializujem sa na tvorbu štýlových, moderných webových stránok a online aplikácii.
                </p>
            </div>
            
        </div>
        <div className="i-right">
          <div className="i-bg"></div> 
             <img src={Me} alt="" className="i-img" /> 

        </div>
        <div id="scroll-down-animation">
                <span class="mouse">
                <span class="move2"></span>
                </span>
                <h2>viac nižšie</h2>
            </div>

    </div>
  )
}

export default Intro