import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import {
  HiOutlineChevronDoubleDown,
  HiOutlineChevronDoubleUp,
} from "react-icons/hi";

const Card = ({ item }) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      <div className="card">
        <img src={item.img} alt="" />
        <div className="card-body">
          <h3>{item.heading}</h3>
          <p>{item.para}</p>
          <p {...getCollapseProps()}>{item.show}</p>
          <div className="contain">
            <a
              className="btn"
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
            >
              {isExpanded ? (
                <HiOutlineChevronDoubleUp />
              ) : (
                <HiOutlineChevronDoubleDown />
              )}
            </a>
            <a href={item.URL} className="btn btn-primary">
              Live Demo
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
