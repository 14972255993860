import React from 'react'
import "./about.css"
import Figma from "../../img/figma.png";
import FB from "../../img/fb.jpg";
import scrollreveal from 'scrollreveal'
import { useEffect } from "react";
import Reactjs from "../../img/react.png";
import Wordpress from "../../img/wordpress.png";
import Woo from "../../img/woo.png";
import Firebase from "../../img/firebase.png";


const About = () => {
  /* useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "30px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(
      `
      .a-right,
      .a-award
      
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []); */

  useEffect(() => {
    const sr = scrollreveal({
      origin: "left",
      distance: "30px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(
      `
      .a-img
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);

  return (
    <div className="a">
        <div className="a-left">
            <div className="a-card bg"></div>
            <div className="a-card">
                <img src={FB}
                     alt="" 
                     className="a-img" 
                />            
            </div>
        </div>
        <div className="a-right">
            <h1 className="a-title">Web Developer | freelancer</h1>
            {/* <h2 className="a-title2">Zopár slov o mne</h2> */}
            <p className="a-sub">
            Ing. Alexander Abrahámek
            </p>
            <p className="a-desc">
            Ako full stack developer webových a mobilných aplikácií poskytujem kompletné služby od prvotného dizajnu, cez vývoj, testovanie, implementáciu až po servis a poradenstvo. Pri svojej práci kladiem dôraz na to, aby bol produkt atraktívny pre použivateľa a pritom plne funkčný a optimalizovaný pre všetky zobrazovacie zariadenia. Zvolené technológie a frameworky použité pri vývoji potom závisia na typu a náročnosti zadania. Aktuálne tvorím hlavne pomocou: Figma, React js, Node js, Firebase, WordPress, MongoDB. 
            </p>
            <div className="a-award">
                <img src={Figma} alt="Figma" title="Figma" className="figma" />
                <img src={Reactjs} alt="React js" title="React js" className="react" />
                <img src={Firebase} alt="Firebase" title="Firebase" className="firebase" />
                <img src={Wordpress} alt="Wordpress" title="Wordpress" className="wordpress" />
                <img src={Woo} alt="Woo commerce" title="Woo commerce" className="woo" />
                
                
          {/*       <div className="a-award-texts">
                    <h4 className="a-award-title">International Design Awards 2021</h4>
                    <p className="a-award-desc">
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
                    and fugit.
                    </p>
                </div> */}
                
            </div>
            
       
        </div>
    </div>
  )
}

export default About