import React from 'react'
import "./contact.css"
import Phone from "../../img/phone.png"
import Email from "../../img/email.png"
import Address from "../../img/address.png"
import {useRef, useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {

    const formRef = useRef();
    const [done, setDone] = useState(false)
    

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
          .sendForm(
            "service_knno0jo",
            "template_rk12nn9",
            formRef.current,
            "ANrtzgW5WPe4C_CfR"
          )
          .then(
            (result) => {
              console.log(result.text);
              setDone(true)
            },
            (error) => {
              console.log(error.text);
            }
          );
      };

  return (
    <div className="c">
        <div className="c-wrapper">
            <div className="c-left">
                <h1 className="c-title">Prediskutujme váš nápad</h1>
                <div className="c-info">
                    <div className="c-info-item">
                        <img
                         src={Phone}
                         alt=""
                         className="c-icon"
                        />
                        +421 949 325 151
                    </div>
                    <div className="c-info-item">
                        <img
                         src={Email}
                         alt=""
                         className="c-icon"
                        />
                        a.abrahamek@gmail.com
                    </div>
                    <div className="c-info-item">
                        <img
                         src={Address}
                         alt=""
                         className="c-icon"
                        />
                        Karpatská 25, 915 01 Nové Mesto nad Váhom
                    </div>
                </div>
            </div>
            <div className="c-right">
                <p className="c-desc">
                    <b>Aký je váš príbeh?</b> Napíšte mi správu, alebo zavolajte a môžeme spolu prebrať, aký projekt by ste potrebovali vytvoriť, alebo vylepšiť. Som k dispozícii.
                </p>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <input  type="text" placeholder="Vaše meno" name="user_name" />
                    <input  type="text" placeholder="Predmet" name="user_subject" />
                    <input  type="text" placeholder="E-mail" name="user_email" />
                    <textarea name="message" rows="5" placeholder="Správa"></textarea>
                    <button className="btn btn-primary">Odoslať</button>
                    {done && "Thank you..."}
                </form>
     
            </div>
            
        </div>
        
    </div>
    

  )
}

export default Contact
